var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center gap-2 justify-content-between action"},[_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Connected Case")))]),(_vm.ticket.case.status)?_c('p',{staticClass:"status-tag"},[_vm._v(" "+_vm._s(_vm.ticket.case.status)+" ")]):_vm._e()]),_c('b-nav-item-dropdown',{attrs:{"right":"","toggle-class":"d-flex align-items-center"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{},[_c('ElementsIcon',{attrs:{"icon":'barVerticalIcon'}})],1)]},proxy:true}])},[(
          _vm.ticket.case.files['upper_portal_original'] ||
          _vm.ticket.case.files['lower_portal_original']
        )?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([
            _vm.ticket.case.files['upper_portal_original'],
            _vm.ticket.case.files['lower_portal_original'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Download Original"))+" ")])],1):_vm._e(),(_vm.ticket.case.results)?_c('div',[_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([
              _vm.ticket.case.results['upper_rotated'] ||
                _vm.ticket.case.results['upper'],
              _vm.ticket.case.results['lower_rotated'] ||
                _vm.ticket.case.results['lower'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Download Result"))+" ")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([
              _vm.ticket.case.results['upper'],
              _vm.ticket.case.results['lower'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Download Without Rotation"))+" ")])],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"mt-2 px-3"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-12"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between",staticStyle:{"margin-bottom":"8px"}},[_c('h5',[_vm._v(_vm._s(_vm.$t("Case ID")))]),_c('p',[_vm._v(" "+_vm._s(_vm.ticket.case.id)+" ")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between",staticStyle:{"margin-bottom":"8px"}},[_c('h5',[_vm._v(_vm._s(_vm.$t("Case Name")))]),(_vm.ticket.case && _vm.ticket.case.name)?_c('p',[_vm._v(" "+_vm._s(_vm.ticket.case.name)+" ")]):_c('p',[_vm._v("-")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',[_vm._v(_vm._s(_vm.$t("Case Status")))]),(_vm.ticket.case && _vm.ticket.case.caseStatus)?_c('p',{staticClass:"status-tag"},[_vm._v(" "+_vm._s(_vm.ticket.case.caseStatus)+" ")]):_c('p',[_vm._v("-")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }