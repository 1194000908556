<template>
  <div>
    <div class="d-flex align-items-center gap-2 justify-content-between action">
      <div class="d-flex align-items-center gap-2">
        <h3>{{ $t("Connected Case") }}</h3>
        <p class="status-tag" v-if="ticket.case.status">
          {{ ticket.case.status }}
        </p>
      </div>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center"
        class=""
      >
        <template #button-content>
          <div class="">
            <ElementsIcon v-bind:icon="'barVerticalIcon'" />
          </div>
        </template>

        <b-dropdown-item
          v-if="
            ticket.case.files['upper_portal_original'] ||
            ticket.case.files['lower_portal_original']
          "
          link-class="d-flex align-items-center"
          @click="
            downloadFiles([
              ticket.case.files['upper_portal_original'],
              ticket.case.files['lower_portal_original'],
            ])
          "
        >
          <feather-icon size="16" icon="DownloadIcon" class="mr-50" />

          <span>
            {{ $t("Download Original") }}
          </span>
        </b-dropdown-item>
        <div v-if="ticket.case.results">
          <b-dropdown-item
            @click="
              downloadFiles([
                ticket.case.results['upper_rotated'] ||
                  ticket.case.results['upper'],
                ticket.case.results['lower_rotated'] ||
                  ticket.case.results['lower'],
              ])
            "
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="DownloadIcon" class="mr-50" />

            <span>
              {{ $t("Download Result") }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="
              downloadFiles([
                ticket.case.results['upper'],
                ticket.case.results['lower'],
              ])
            "
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="DownloadIcon" class="mr-50" />

            <span>
              {{ $t("Download Without Rotation") }}
            </span>
          </b-dropdown-item>
        </div>
      </b-nav-item-dropdown>
    </div>
    <div class="mt-2 px-3">
      <div class="row align-items-start">
        <div class="col-lg-4 col-md-4 col-12">
          <div
            class="d-flex align-items-center justify-content-between"
            style="margin-bottom: 8px"
          >
            <h5>{{ $t("Case ID") }}</h5>
            <p>
              {{ ticket.case.id }}
            </p>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            style="margin-bottom: 8px"
          >
            <h5>{{ $t("Case Name") }}</h5>
            <p v-if="ticket.case && ticket.case.name">
              {{ ticket.case.name }}
            </p>
            <p v-else>-</p>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <h5>{{ $t("Case Status") }}</h5>
            <p class="status-tag" v-if="ticket.case && ticket.case.caseStatus">
              {{ ticket.case.caseStatus }}
            </p>
            <p v-else>-</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ElementsIcon from "@/components/elements/Icon.vue";
import FileService from "@/services/file.service";
export default {
  components: {
    ElementsIcon,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  methods: {
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
  },
};
</script>
