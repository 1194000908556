<template>
  <div class="ticket_view">
    <page-header :items="items">
      <template #buttons>
        <loading-button
          v-if="$can(`${$route.meta.permission}.edit`)"
          @click="updateTicket"
          :Loading="isLoading"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <span>{{ $t("Update") + " " + $t("Ticket") }}</span>
        </loading-button>
      </template>
    </page-header>
    <validation-observer ref="simpleRules">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="card-right" v-if="isReportedCase">
                <ReportedCase :ticket="ticket" />
              </div>

              <div class="card-right mt-1">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <ElLabel
                        class="form-label"
                        for-label="title"
                        :title="$t('Title')"
                        :is-required-icon="true"
                      />
                      <validation-provider
                        #default="{ errors }"
                        name="Title"
                        rules="required"
                      >
                        <input
                          id="title"
                          v-model="form.title"
                          name="title"
                          class="form-control"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" v-if="!!ticket.ticketNumber">
                      <label class="form-label">{{ $t("Ticket") }}</label>
                      <div class="form-control disabled">
                        <p class="m-0">
                          {{ ticket.ticketNumber }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t("Created") }}</label>
                      <div class="form-control disabled">
                        <p v-if="ticket.createdAt" class="m-0">
                          {{
                            $dateFormatter(
                              formatTime(ticket.createdAt || ""),
                              $i18n.locale
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <div class="form-group">
                      <ElLabel
                        class="form-label"
                        for-label="groups"
                        :title="$t('Status')"
                      />
                      <select class="form-control" v-model="form.status">
                        <option value="new">
                          {{ $t("New") }}
                        </option>
                        <option value="open">
                          {{ $t("Open") }}
                        </option>
                        <option value="waiting-for-response">
                          {{ $t("Waiting For Response") }}
                        </option>
                        <option value="pending">
                          {{ $t("Pending") }}
                        </option>
                        <option value="resolved">
                          {{ $t("Resolved") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <ElLabel
                        class="form-label"
                        for-label="groups"
                        :title="$t('Priority')"
                      />
                      <select class="form-control" v-model="form.priority">
                        <option value="low">{{ $t("Low") }}</option>
                        <option value="normal">{{ $t("Normal") }}</option>
                        <option value="high">{{ $t("High") }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <div class="form-group m-0">
                      <ElLabel
                        class="form-label"
                        for-label="groups"
                        :title="$t('Reporter')"
                      />
                      <div
                        v-if="!!ticket.userId"
                        class="d-flex align-items-center gap-2"
                      >
                        <img
                          v-if="
                            userProfiles[ticket.userId] &&
                            userProfiles[ticket.userId].profile_image
                          "
                          :src="userProfiles[ticket.userId].profile_image"
                          alt=""
                          style="
                            width: 35px;
                            height: 35px;
                            object-fit: cover;
                            border-radius: 50%;
                          "
                        />
                        <h5 v-if="userProfiles[ticket.userId]" class="ms-1">
                          {{
                            (userProfiles[ticket.userId].first_name || "") +
                            " " +
                            (userProfiles[ticket.userId].last_name || "")
                          }}
                        </h5>
                      </div>
                      <div v-else class="d-flex align-items-center">
                        <img
                          src="../../assets/images/default.png"
                          style="
                            width: 35px;
                            height: 35px;
                            object-fit: cover;
                            border-radius: 50%;
                          "
                        />
                        <h5 class="ms-1"></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-right mt-1">
                <div
                  v-if="$can(`backoffice-ticket-comment.create`)"
                  class="add_comm mb-3"
                  id="Comment"
                >
                  <b-tabs v-model="commentType">
                    <b-tab :title="$t('Internal')"></b-tab>
                    <b-tab :title="$t('Public')"></b-tab>
                  </b-tabs>
                  <div
                    class="form-group m-0 editor"
                    v-show="ticket.state !== 'resolved'"
                  >
                    <MarkdownEditor
                      :content="commentText || ''"
                      @change="commentText = $event"
                      :builder-images="[]"
                      :editable="true"
                      @imageAdded="addCommentImages"
                    />
                  </div>
                  <div v-if="hasCommentError">
                    <span class="text-danger ms-1"
                      >{{ $t("This value is required") }}.</span
                    >
                  </div>
                  <div class="dropzone-wrapper mt-2" v-if="enableAttachment">
                    <div class="dropzone" v-show="ticket.state !== 'resolved'">
                      <vue-dropzone
                        id="dropzone"
                        ref="myDropzone"
                        :disabled="ticket.state === 'resolved' || false"
                        :options="dropzoneOptions"
                      />
                    </div>
                    <show-files
                      :commentFiles="commentFiles"
                      @remove="deleteUpload"
                    />
                  </div>
                  <div
                    class="add_bottom d-flex mt-2"
                    :class="{
                      'justify-content-end': ticket.state === 'resolved',
                    }"
                  >
                    <div
                      class="d-flex align-items-center gap-2"
                      v-if="ticket.state !== 'resolved'"
                    >
                      <button
                        :title="ticket.state === 'resolved' ? 'disabled' : ''"
                        @click="createComment"
                        :class="{
                          'cursor-not-allowed': isTicketCommentTextInvalid,
                        }"
                        :disabled="ticket.state === 'resolved' || !commentBtn"
                        class="btn btn-primary waves-effect waves-light"
                      >
                        {{
                          $t(
                            `Create ${
                              commentType == 0
                                ? "Internal Note"
                                : "Public Answer"
                            }`
                          )
                        }}
                      </button>
                      <div
                        :title="ticket.state === 'resolved' ? 'disabled' : ''"
                      >
                        <label
                          for="ticket-file"
                          class="btn btn-primary waves-effect waves-light custom-file-upload"
                        >
                          <i
                            class="fas fa-cloud-upload-alt text-white"
                            style="font-size: 15px"
                          ></i>
                          <span class="ms-1">{{ $t("Add Attachment") }}</span>
                        </label>
                        <button
                          id="ticket-file"
                          class="form-control"
                          :disabled="ticket.state === 'resolved'"
                          @click="openDropZone()"
                          style="display: none"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <template
                  v-if="
                    comments &&
                    comments.length > 0 &&
                    $can(`backoffice-ticket-comment.list`)
                  "
                >
                  <div
                    v-for="comment in comments || []"
                    :key="'comment-' + comment.id"
                  >
                    <div :id="'comment-' + comment.id" :class="['comment']">
                      <div class="comment-header">
                        <div class="comment-user">
                          <img
                            :title="comment.user.email || ''"
                            v-if="
                              !!userProfiles[comment.userId] &&
                              !!userProfiles[comment.userId].profile_image
                            "
                            :src="userProfiles[comment.userId].profile_image"
                            class="comment-user-img"
                          />
                          <img
                            v-else
                            :title="
                              (userProfiles[comment.userId] &&
                                userProfiles[comment.userId].email) ||
                              ''
                            "
                            src="../../assets/images/default.png"
                            class="comment-user-img"
                          />
                          <div class="comment-user-title">
                            <h3
                              v-if="
                                userProfiles[comment.userId] &&
                                (userProfiles[comment.userId].first_name ||
                                  userProfiles[comment.userId].last_name)
                              "
                            >
                              {{
                                (userProfiles[comment.userId].first_name ||
                                  "") +
                                " " +
                                (userProfiles[comment.userId].last_name || "")
                              }}
                            </h3>
                            <h3 v-else>{{ $t("No Name") }}</h3>
                          </div>
                        </div>
                        <div
                          v-if="
                            user &&
                            comment &&
                            ticket &&
                            user.id === comment.userId &&
                            comment.isArchived === 0 &&
                            ticket.isArchived === 0
                          "
                          class="dropdown"
                        >
                          <i
                            class="hidden cursor-pointer mt-5 mr-2 fa-solid fa-ellipsis-vertical"
                          ></i>
                          <div class="dropdown-content">
                            <p
                              class="pt-2 pl-2 pb-2 cursor-pointer dropdown-action"
                            >
                              {{ $t("Edit") }}
                            </p>
                            <hr />
                            <p
                              class="pt-2 pl-2 pb-2 cursor-pointer dropdown-action"
                            >
                              {{ $t("Delete") }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="comment-body">
                        <div class="comment-text preview-mode">
                          <div
                            v-if="user.id == comment.userId"
                            :class="{
                              'is-archived': comment.isArchived == 1,
                            }"
                          >
                            <MarkdownEditor
                              :content="comment.text || ''"
                              :builder-images="comment.images || []"
                              :editable="false"
                            />
                          </div>

                          <p
                            v-if="errorTicketCommentId == comment.id"
                            style="color: red"
                          >
                            {{ $t("This form is required to be filled") }}
                          </p>
                          <div
                            class="d-flex align-items-center justify-content-between w-100 mt-2"
                          >
                            <p>
                              <span v-if="comment.createdAt">
                                {{
                                  $dateFormatter(
                                    formatTime(comment.createdAt || ""),
                                    $i18n.locale
                                  )
                                }}
                              </span>
                              <span v-else>
                                {{ $t("Accounted Time") }}:
                                {{ $t("Not Set") }}
                              </span>
                              {{
                                comment.isArchived === 1
                                  ? " - " + $t("Is Deleted")
                                  : ""
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <CommentFooter
                        v-if="
                          comment.attachments && comment.attachments.length > 0
                        "
                        :attachments="comment.attachments"
                      />
                    </div>
                  </div>
                </template>
                <p
                  v-if="
                    (comments.length || 0) < ticketCommentsTotal &&
                    $can('bacloffice-ticket-comment.list')
                  "
                  class="text-align-center"
                  ref="load"
                >
                  {{ $t("Loading more ...") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
    <!-- <div class="img-prev-modal" v-if="isPrevShow">
      <div class="modal-content">
        <img class="img-prev" :src="isImgPreview" alt="" />
        <div class="close-modal" @click="closeImgPrev">
          <feather-icon size="18" icon="XIcon" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Quill } from "vue-quill-editor/src";
import PageHeader from "@/components/PageHeader.vue";
import ElLabel from "@/components/ElLabel.vue";
import vueDropzone from "vue2-dropzone";
import "@/assets/scss/pages/_tickets.scss";
import ShowFiles from "@/components/ticketSystem/show-files.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import MarkdownEditor from "@/components/MarkdownEditor.vue";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput.vue";
import ReportedCase from "@/components/ticketSystem/ReportedCase.vue";
import CommentFooter from "@/components/ticketSystem/CommentFooter.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import usersStoreMixin from "@/mixins/usersStoreMixin";
import ElementsIcon from "@/components/elements/Icon.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  mixins: [usersStoreMixin],
  components: {
    LoadingButton,
    ShowFiles,
    CommentFooter,
    ElLabel,
    PageHeader,
    vueDropzone,
    TextInput,
    ReportedCase,
    MarkdownEditor,
    MultiSelectInput,
    ElementsIcon,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      ticketCommentsTotal: 0,
      errorTicketCommentId: null,
      commentType: 0,
      title: "",
      submitted: false,
      commentText: "",
      commentBtn: true,
      hasCommentError: false,
      observer: new IntersectionObserver(this.infiniteScroll),
      perPage: 10,
      ticket: {
        title: "",
        state: "",
        priority: "",
        visibility: "",
        createdAt: "",
      },
      form: {
        title: "",
        priority: "",
        createdAt: "",
      },
      isLoading: false,
      enableAttachment: false,
      commentFile: "",
      commentFiles: [],
      comments: [],
      isCloseTicket: true,
      isReportedCase: false,
      images: [],
      templateId: null,
    };
  },
  computed: {
    ...mapGetters(["showLoader"]),
    ...mapGetters("tickets", ["tickets"]),
    ...mapGetters("ticketComments", []),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("users", ["userProfiles"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/",
        },
        {
          text: this.$t("Tickets"),
          to: "/tickets",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },

    dropzoneOptions() {
      const component = this; // Capture the component context
      return {
        url: process.env.backEndUrl + "/api/convert-file-to-base64",
        autoProcessQueue: false,
        maxFiles: 5,
        accept: function (file, done) {
          done();
        },
        acceptedFiles:
          ".jpg,.jpeg,.png,.pdf,.csv,.doc,.docx,.ppt,.pptx,.xls,.xlsx",
        dictDefaultMessage: this.$t("Click or drag file here to upload"),
        addRemoveLinks: true,
        init: function () {
          this.on("addedfile", function (file) {
            component.processFile(file); // Use the component context
          });
        },
      };
    },
    isFormInvalid() {
      return !this.form.title;
    },
    isTicketCommentTextInvalid() {
      return !this.commentText;
    },
  },
  methods: {
    updateDropzoneMessage() {
      if (this.$refs.myDropzone && this.$refs.myDropzone.dropzone) {
        const dropzoneInstance = this.$refs.myDropzone.dropzone;
        dropzoneInstance.options.dictDefaultMessage = this.$t(
          "Click or drag file here to upload"
        );
        dropzoneInstance.element.querySelector(".dz-message").textContent =
          this.$t("Click or drag file here to upload");
      }
    },
    addCommentImages(event) {
      this.images = event;
    },
    customLabel(props) {
      return `${props?.first_name || ""} ${props?.last_name || ""}`;
    },

    async assignDataComments(response) {
      if (!!response) {
        this.ticketCommentsTotal = response?.length ?? 0;
        this.comments = response ?? [];
        await this.getUserProfiles(
          this.comments.map((comment) => comment.userId)
        );
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.perPage += 10;
        if (this.$can("backoffice-ticket-comment.list")) {
          const responseComments = await this.$store.dispatch(
            "ticketComments/list",
            {
              params: {
                ticketId: this.$route.params.id,
                perPage: this.perPage,
                sortOrder: "desc",
                sortBy: "created_at",
              },
            }
          );

          await this.assignDataComments(responseComments?.data?.data);
        }
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        const response = await this.$store.dispatch(
          "tickets/show",
          this.$route.params.id
        );

        this.ticket = response?.data?.data ?? {};
        this.form.priority = this.ticket.priority;
        this.form.status = this.ticket.status;
        this.form.dueDate = this.ticket.dueDate;
        this.form.title = this.ticket.title;
        if (this.ticket && this.ticket.case) this.isReportedCase = true;
        if (this.$can("backoffice-ticket-comment.list")) {
          const responseComments = await this.$store.dispatch(
            "ticketComments/list",
            {
              params: {
                perPage: this.perPage,
                sortBy: "created_at",
                sortOrder: "desc",
                ticketId: this.$route.params.id,
              },
            }
          );
          await this.assignDataComments(responseComments?.data?.data);
        }
        const usersToBeFetched = [this.ticket.userId];
        await this.getUserProfiles(usersToBeFetched);
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    formatTime(timestamp) {
      const utcDate = new Date(timestamp);
      const offset = utcDate.getTimezoneOffset() * 60000; // Offset in milliseconds
      const localTimestamp = utcDate.getTime() - offset;
      const localDate = new Date(localTimestamp);
      return localDate.toLocaleString();
    },
    htmlFromText(text) {
      try {
        let article = document.createElement("article");
        let quill = new Quill(article);
        quill.setContents(JSON.parse(text));
        return quill.root.innerHTML;
      } catch (err) {
        return md.render(text);
      }
    },
    getInitials(name) {
      const tokens = name?.split(" ");
      if (tokens)
        return `${tokens?.[0]?.[0] ?? ""}${
          tokens?.[1]?.[0] ?? ""
        }`.toUpperCase();
      else return "";
    },
    processFile(file) {
      var reader = new FileReader();
      reader.onload = (event) => {
        const fileName = file.name;
        const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
        const fileExtension = fileName
          .substring(fileName.lastIndexOf(".") + 1)
          .toLowerCase();

        const dataUri = event.target.result;
        const parts = dataUri.split(",");
        if (parts.length === 2) {
          let file = {
            name: fileName,
            type: fileExtension,
            size: `${fileSizeMB} MB`,
            base64: parts?.[1] ?? "",
          };
          this.commentFiles.push(file);
        }
      };
      reader.readAsDataURL(file);
      this.$refs.myDropzone.removeAllFiles();
    },
    async createComment(baypass = true) {
      try {
        if (this.commentText == "" || this.commentText == null) {
          this.hasCommentError = true;
          return false;
        }
        let attachments = [];
        this.commentBtn = false;
        if (!!this.commentFiles)
          attachments = Array.from(new Set(this.commentFiles));

        this.isCloseTicket = baypass;
        this.$store.commit("showLoader", true);
        await this.$store
          .dispatch("ticketComments/create", {
            text: this.commentText,
            images: this.images,
            ticketId: this.$route.params.id,
            visibility: this.commentType == 0 ? "internal" : "public",
            attachments: attachments,
            userId: localStorage.getItem("user_id"),
          })
          .then(async (response) => {
            const userIds = response.data?.userIds;
            let usersMails = [];
            if (userIds) {
              await this.getUserProfiles(userIds);
              usersMails = Object.values(this.userProfiles)
                .filter((profile) => profile?.email)
                .map((profile) => profile.email);
            }
            if (this.templateId) {
              await this.$store.dispatch("mailTemplates/sendMailTemplate", {
                ...this.templateId,
                id: this.templateId?.mailTemplateId,
                from: this.templateId?.senderMail,
                mails: usersMails,
                data: {
                  text: this.commentText,
                  images: this.images,
                  ticketId: this.$route.params.id,
                  visibility: this.commentType == 0 ? "internal" : "public",
                  attachments: attachments,
                  userId: localStorage.getItem("user_id"),
                },
              });
              await this.loadItems();
            }
            this.commentText = "";
            this.commentFiles = [];
            this.images = [];
          })
          .catch((error) => {
            this.commentText = "";
            this.commentFiles = [];
            this.images = [];
            console.log("error", error);
          })
          .finally(() => {
            this.$store.commit("showLoader", false);
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("showLoader", false);
        this.commentBtn = true;
        this.hasCommentError = false;
      }
    },
    ready() {
      try {
        this.$refs.quillEditor.setContents(JSON.parse(this.comment));
      } catch (e) {
        this.$refs.quillEditor.setContents(this.comment ?? "");
      }
    },
    deleteUpload(index) {
      this.commentFiles.splice(index, 1);
    },
    openDropZone() {
      this.enableAttachment = true;
    },
    openImgPrev() {
      this.isPrevShow = true;
    },
    closeImgPrev() {
      this.isPrevShow = false;
    },
    async updateTicket() {
      this.$store.commit("showLoader", true);
      this.submitted = true;
      try {
        this.$refs.simpleRules.validate().then(async (success) => {
          if (success) {
            await this.$store
              .dispatch("tickets/update", {
                id: this.$route.params.id,
                data: {
                  ...this.form,
                },
              })
              .then(async (response) => {
                const userIds = response.data?.userIds;
                let usersMails = [];
                if (userIds) {
                  await this.getUserProfiles(userIds);
                  usersMails = Object.values(this.userProfiles)
                    .filter((profile) => profile?.email)
                    .map((profile) => profile.email);
                }
                if (this.templateId) {
                  await this.$store.dispatch("mailTemplates/sendMailTemplate", {
                    ...this.templateId,
                    id: this.templateId?.mailTemplateId,
                    from: this.templateId?.senderMail,
                    mails: usersMails,
                    data: {
                      ...this.form,
                    },
                  });
                }
                this.$router.push("/tickets");
              });
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("showLoader", false);
        this.submitted = false;
      }
    },
  },
  async mounted() {
    this.updateDropzoneMessage(); // Set initial message
    this.$watch("$i18n.locale", () => {
      this.updateDropzoneMessage(); // Update message when language changes
    });
    await this.loadItems();
    const response = await this.$store.dispatch(
      "mailTemplates/mailTemplateAssignmentList"
    );
    this.templateId =
      response?.data?.data.find(
        (template) => template.module === "ticketSystemTemplate"
      ) ?? null;
  },
  watch: {
    ticketCommentsTotal() {
      this.$nextTick(() => {
        if (this.$refs.load) {
          this.observer?.observe(this.$refs.load);
        }
      });
    },
  },
};
</script>

<style lang="scss">
body.dark-layout
  .preview-mode
  .mavonEditor
  .v-note-wrapper
  .v-note-show
  .v-show-content {
  border: none !important;
  background: transparent !important;
}
.preview-mode .markdown-body {
  border: none !important;
  background: transparent !important;
}
.preview-mode {
  .mavonEditor .v-note-wrapper .v-note-show .v-show-content {
    background: transparent !important;
  }
}
.preview-mode .v-note-op {
  display: none !important;
}
.preview-mode .v-note-edit {
  display: none !important;
}
.preview-mode .v-note-panel {
  display: block !important;
}
.preview-mode .v-show-content {
  overflow: auto !important;
  background-color: white !important;
  padding: 0 !important;
}
.preview-mode .v-note-show {
  width: 100% !important;
}
.preview-mode .v-note-wrapper {
  z-index: 1;
  min-height: auto;
  padding: 0;
}

.preview-mode .v-note-wrapper p {
  margin-bottom: 0;
}

.preview-mode .markdown-body {
  height: auto;
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
