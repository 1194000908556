<template>
  <div>
    <div class="comment-footer">
      <div class="row mt-1">
        <div
          class="col-md-4"
          v-for="attachment in attachments"
          :key="attachment.id"
        >
          <div class="attachment-card p-1 m-0">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center gap-2">
                <div class="">
                  <span
                    class="text-primary"
                    target="_blank"
                    style="cursor: pointer"
                    @click="
                      downloadAttachments(
                        attachment.id,
                        attachment.viewableName
                      )
                    "
                  >
                    <span v-if="isImageFile(attachment.viewableName)">
                      <img
                        src="@/assets/images/extensions/img.png"
                        alt="Image"
                      />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'jpg')">
                      <img src="@/assets/images/extensions/jpg.png" alt="jpg" />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'png')">
                      <img src="@/assets/images/extensions/png.png" alt="png" />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'pdf')">
                      <img src="@/assets/images/extensions/pdf.png" alt="pdf" />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'doc')">
                      <img
                        src="@/assets/images/extensions/doc.png"
                        alt="docs"
                      />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'docx')">
                      <img
                        src="@/assets/images/extensions/docx.png"
                        alt="docx"
                      />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'xls')">
                      <img src="@/assets/images/extensions/xls.png" alt="xls" />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'xlsx')">
                      <img
                        src="@/assets/images/extensions/xlsx.png"
                        alt="xlsx"
                      />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'ppt')">
                      <img src="@/assets/images/extensions/ppt.png" alt="ppt" />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'pptx')">
                      <img
                        src="@/assets/images/extensions/pptx.png"
                        alt="pptx"
                      />
                    </span>
                    <span v-else-if="isFile(attachment.viewableName, 'csv')">
                      <img src="@/assets/images/extensions/csv.png" alt="csv" />
                    </span>
                    <span v-else>
                      <img
                        src="@/assets/images/extensions/img.png"
                        alt="Image"
                      />
                    </span>
                  </span>
                </div>
                <div class="">
                  <a
                    @click="
                      downloadAttachments(
                        attachment.id,
                        attachment.viewableName
                      )
                    "
                    href="javascript:void(0);"
                    class="font-weight-semibold"
                  >
                    {{ attachment.viewableName }}</a
                  >
                  <p class="mb-0">
                    {{ attachment.size }}
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center gap-2">
                <span
                  class="cursor-pointer"
                  @click="
                    downloadAttachment(attachment.id, attachment.viewableName)
                  "
                >
                  <feather-icon size="18" icon="DownloadIcon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-prev-modal" v-if="isPrevShow">
      <div class="modal-content">
        <img class="img-prev" :src="isImgPreview" alt="" />
        <div class="close-modal" @click="closeImgPrev">
          <feather-icon size="18" icon="XIcon" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDownloadingFile: false,
      isPrevShow: false,
      isImgPreview: "",
    };
  },

  methods: {
    async downloadAttachments(id, fileName) {
      if (!this.isDownloadingFile) {
        try {
          this.$store.commit("showLoader", true);
          this.isDownloadingFile = true;
          let dataURI;
          // Make an Axios GET request to fetch the Base64 image data
          const response = await this.$store.dispatch(
            "ticketComments/downloadAttachment",
            id
          );
          // Extract the Base64 data from the response
          const base64Data = response?.data?.base64;
          // Create a Data URI for the Base64 image
          if (this.isImageFile(fileName)) {
            //dataURI = "data:image/jpg;base64," + base64Data; // Adjust the MIME type as needed
            this.isImgPreview = base64Data;
            this.isPrevShow = true;

            this.isDownloadingFile = false;
            return;
          } else {
            //dataURI = "data:application/pdf;base64," + base64Data;
          }
          // Create a temporary anchor element for downloading
          const a = document.createElement("a");
          a.href = base64Data;
          a.download = fileName || "download"; // Use 'download' as the default filename if fileName is not provided
          a.style.display = "none";

          // Append the anchor element to the document body
          document.body.appendChild(a);

          // Trigger a click event on the anchor element to start the download
          a.click();

          // Remove the anchor element
          document.body.removeChild(a);
        } catch (error) {
          console.error("Error downloading image:", error);
        } finally {
          this.$store.commit("showLoader", false);
        }

        this.isDownloadingFile = false;
      }
    },
    async downloadAttachment(id, fileName) {
      if (!this.isDownloadingFile) {
        try {
          this.$store.commit("showLoader", true);
          this.isDownloadingFile = true;

          // Fetch the Base64 data from the API
          const response = await this.$store.dispatch(
            "ticketComments/downloadAttachment",
            id
          );

          // Extract Base64 data and determine MIME type
          const base64Data = response?.data?.base64;
          if (!base64Data) {
            throw new Error("No data received for the attachment.");
          }

          // Determine MIME type based on file extension
          const mimeType =
            this.getMimeType(fileName) || "application/octet-stream";

          // Create a Data URI
          let dataURI;
          if (!base64Data.includes(mimeType)) {
            dataURI = `data:${mimeType};base64,${base64Data}`;
          } else {
            dataURI = base64Data;
          }

          // Create a temporary anchor element for downloading
          const a = document.createElement("a");
          a.href = dataURI;
          a.download = fileName || "download"; // Use 'download' as the default filename if fileName is not provided
          a.style.display = "none";

          // Append the anchor to the document body
          document.body.appendChild(a);

          // Trigger a click event on the anchor element to start the download
          a.click();

          // Remove the anchor element
          document.body.removeChild(a);
        } catch (error) {
          console.error("Error downloading the file:", error);
        } finally {
          this.$store.commit("showLoader", false);
          this.isDownloadingFile = false;
        }
      }
    },
    getMimeType(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      const mimeTypes = {
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        pdf: "application/pdf",
        doc: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        txt: "text/plain",
        csv: "text/csv",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        zip: "application/zip",
      };

      return mimeTypes[extension] || "application/octet-stream"; // Default to binary data
    },
    isImageFile(fileName) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = fileName.split(".").pop().toLowerCase();
      return imageExtensions.includes(fileExtension);
    },
    isFile(fileName, fileType) {
      const pdfExtensions = [fileType];
      const fileExtension = fileName.split(".").pop().toLowerCase();
      return pdfExtensions.includes(fileExtension);
    },
    openImgPrev() {
      this.isPrevShow = true;
    },
    closeImgPrev() {
      this.isPrevShow = false;
    },
  },
};
</script>
